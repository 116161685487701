<template>
  <div class="wrap">
    <header-nav></header-nav>
    <b-container>
      <b-row class="swiper" ref="swiper">
        <b-col class="col-sm-12 col-md-12 col-lg-9 col-xl-9">
          <div class="magnifier-box swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item, index) in homeSlide" :key="index">
                <img :src="item.imgUrl" alt="">
              </div>
            </div>
          </div>
        </b-col>
        <b-col class="col-sm-12 col-md-12 col-lg-3 col-xl-3 split-line">
          <div class="swiper-container gallery-thumbs">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item, index) in homeSlide" :key="index">
                <img :src="item.imgUrl" alt="">
              </div>
            </div>
          </div>
          <div class="thumb-slide-prev" v-if="swiperNavigation">
            <i class="el-icon-arrow-up"></i>
          </div>
          <div class="thumb-slide-next" v-if="swiperNavigation">
            <i class="el-icon-arrow-down"></i>
          </div>
        </b-col>
      </b-row>
      <b-row class="introduce-wrap">
        <b-col class="col-sm-12 col-md-12 col-lg-9 col-xl-9">
          <div class="introduce-title">
            {{topDetails.setmeal_name}}
            <span class="measure-area" v-if="topDetails.hurse_type">{{topDetails.hurse_type.split("|")[1]}}</span>
            <span class="hourse-type" v-if="topDetails.hurse_type">{{topDetails.hurse_type.split("|")[0]}}</span>
          </div>
          <div class="introduce-inner">{{topDetails.setmeal_introduce}}</div>
        </b-col>
        <b-col class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
          <div class="btns">
            <div class="secondary-creation-btn" v-if="topDetails.scheme_id" @click="toScheme(topDetails.scheme_id)">
              <img src="@/../public/img/secondary-creation-btn.png" alt="">
            </div>
            <div class="scheme-btn" v-if="topDetails.three_d_url" @click="openThreed(topDetails.three_d_url)">
              <img src="@/../public/img/scheme-btn.png" alt="">
              <div class="qrcode" @click.stop>
                <i class="arrow"></i>
                <div class="qrcode-inner">
                  <vue-qr :text="topDetails.three_d_url" :size="250"></vue-qr>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="special_election" v-for="items in packageList" :key="items.name">
            <div class="special_top">
              <img src="@/../public/packageDetails/sjmj11.png" alt="">
              <span>{{items.name}}</span>
            </div>
            <div class="special_cont" v-for="obj in items.items" :key="obj.name">
              <div class="special_cont_tar">
                <div class="tar_name">
                  <span class="space_one">{{obj.name}}</span>
                  <span class="choose_num">已选{{obj.selectedQuantity}}件商品</span>
                </div>
              </div>
              <b-row class="goods_items">
                <b-col class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 goods_item" v-for="(item, index) in obj.items" :key="index">
                  <div class="select_wrap">
                    <el-checkbox v-model="item.checked" @change="changeChecked(item, obj)">{{item.checked ? '已选' : ''}}</el-checkbox>
                  </div>
                  <div class="pic" @click="toProductDetail(item)">
                    <img :src="item.goods_img" alt="">
                  </div>
                  <div class="detail">
                    <div class="model">价格：{{item.price}}</div>
                    <div class="size" v-if="item.goods_parameter">尺寸：{{item.goods_parameter.split(",")[0].slice(6)}}</div>
                    <div class="material" v-if="item.goods_parameter">材质：{{item.goods_parameter.split(",")[1].slice(6)}}</div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
          <!-- 丰富区开始 -->
          <div class="rich-products-area">
            <div class="rich-products-title">
              <div class="title-name">丰富区</div>
            </div>
            <b-row class="goods-items">
              <b-col class="col-sm-6 col-md-4 col-lg-3 col-xl-3 goods-item" v-for="(item, index) in richProductsList" :key="index">
                <div class="pic" @click="toProductDetail(item)">
                  <img :src="item.goods_img" alt="">
                </div>
                <div class="detail">
                  <div class="compound-wrap">
                    <div class="name">{{item.goods_name}}</div>
                    <div class="select">
                      <el-checkbox v-model="item.checked" @change="richChangeChecked(item)"></el-checkbox>
                    </div>
                  </div>
                  <div class="pirce">
                    <span class="original-price" v-if="item.price != item.discountPrice">¥{{item.price}}</span>
                    <span class="discount">优惠价 ¥{{item.discountPrice}}</span>
                  </div>
                  <!-- <div class="model">型号：{{item.model}}</div> -->
                  <div class="size">尺寸：{{item.goods_parameter.split(",")[0].slice(6)}}</div>
                  <div class="material">材质：{{item.goods_parameter.split(",")[1].slice(6)}}</div>
                </div>
              </b-col>
            </b-row>
          </div>
          <!-- 丰富区结束 -->
          <!-- <div class="join-product">
            <div class="join-product-btn">添加更多商品</div>
          </div> -->
          <div class="package-bottom">
            <div class="selected-product" v-show="show">
              <i class="el-icon-circle-close" @click="close"></i>
              <div class="selected-inner">
                <div class="pic-item" v-for="(item, index) in selectItem" :key="index" :style="`background-image:url(${item.goods_img});`" @click="toProductDetail(item)"></div>
              </div>
            </div>
            <div class="total-price-information">
              <div class="quantity" title="查看已选商品">
                <img src="@/../public/packageDetails/sjmj29.png" alt="" />
                <span class="text">已选商品</span>
                <span class="num">{{totalQuantity}}</span>
                <span class="text">件</span>
              </div>
              <div class="original">
                <span class="original-name">套餐原价：</span>
                <span class="original-price">¥ {{totalPrice}}.00</span>
              </div>
              <div class="discount">
                <span class="discount-name">优惠：</span>
                <span class="preferential-price">- ¥ {{totalPrice - totalDiscountPrice}}.00</span>
              </div>
              <div class="actual">
                <span class="actual-name">实际支付：</span>
                <span class="actual-price">¥ {{totalDiscountPrice}}.00</span>
              </div>
              <div class="operation">
                <span class="export-list" @click="submitOrders('dc')">导出清单</span>
                <span class="submit-orders" @click="submitOrders('tj')">提交订单</span>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <right></right>
    <footer-nav></footer-nav>
  </div>
</template>

<script>
  import headerNav from "@/components/Navbar";
  import right from "@/components/Right";
  import footerNav from "@/components/Footer"; 
  import Swiper from "swiper/swiper-bundle.min";
  import 'swiper/swiper-bundle.min.css';
  import dump from "@/util/dump";
  import url from "@/api/config";
  import vueQr from 'vue-qr';
  export default {
    components: {
      headerNav,
      footerNav,
      right,
      vueQr
    },
    data () {
      return {
        galleryTop: null,
        galleryThumbs: null,
        packageList: [
          {
            name: "客厅",
            items: [
              {
                name: "沙发",
                selectedQuantity: 0,
                items: []
              },
              {
                name: "茶几",
                selectedQuantity: 0,
                items: []
              }
            ]
          },
          {
            name: "餐厅",
            items: [
              {
                name: "餐桌",
                selectedQuantity: 0,
                items: []
              },
              {
                name: "餐椅",
                selectedQuantity: 0,
                items: []
              }
            ]
          },
          {
            name: "主卧",
            items: [
              {
                name: "主卧床",
                selectedQuantity: 0,
                items: []
              },
              {
                name: "床头柜",
                selectedQuantity: 0,
                items: []
              }
            ]
          },
          {
            name: "次卧",
            items: [
              {
                name: "次卧床",
                selectedQuantity: 0,
                items: []
              },
              {
                name: "床头柜",
                selectedQuantity: 0,
                items: []
              }
            ]
          }
        ],
        richProductsList: [],
        checked: true,
        direction: "vertical",
        swiperNavigation: true,
        setmeal_id: "",
        topDetails: {}, //套餐详情
        coverImg: "", //套餐封面图
        homeSlide: [], //套餐图列表
        selectItem: [],
        totalPrice: 0,
        totalDiscountPrice: 0,
        totalQuantity: 0,
        show: true,
        discount: 1, //套餐折扣
      }
    },
    created() {
      const roleId = localStorage.getItem("role_id");
      if(roleId == 3 || roleId == 4){
        this.discount = 1;
      }else if(roleId == 5 || roleId == 6 || roleId == 7){
        this.discount = 0.45;
      }else if(roleId == "38e045588e0e4ce19b80ca40f5325934"){
        this.discount = 0.35;
      }else if(roleId == "de9de2f006e145a29d52dfadda295353"){
        this.discount = 0.34;
      }
      this.setmeal_id=this.$route.query.setmeal_id;
      this.getSetmealDetails();
      this.getSetmealProduct();
    },
    mounted() {
      
      this.bannerSwiper();
      
      addEventListener("resize", () => {
        this.bannerSwiper()
      },false);
      
    },
    methods: {
      bannerSwiper(){
        let width = this.$refs.swiper.clientWidth;
        const swipe = () => {
          this.galleryThumbs = new Swiper('.gallery-thumbs', {
            spaceBetween: 10,
            slidesPerView: 4,
            freeMode: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            observer:true,
            observeParents:true,
            direction: this.direction,
          });
          this.galleryTop = new Swiper('.magnifier-box', {
            spaceBetween: 10,
            loop: true,
            pauseOnMouseEnter: true,
            observer:true,
            observeParents:true,
            thumbs: {
              swiper: this.galleryThumbs
            },
            navigation: {
              nextEl: ".thumb-slide-next",
              prevEl: ".thumb-slide-prev",
            }
          });
        }
        
        
        this.$nextTick(() => {
          if(width <= 720){
            this.direction = "horizontal";
            this.swiperNavigation = false;
            this.$refs.swiper.children[1].classList.remove("split-line");
            this.$refs.swiper.children[1].classList.add("margin-top-10");
          }else{
            this.direction = "vertical";
          }
          swipe();
        })
      },
      // 获取套餐详情 
      getSetmealDetails(){
        this.$api.setmealDetails({
          setmeal_id: this.setmeal_id
        }).then(res => {
          console.log(res)
          if (res.status == 100) {
            // if (res.data.team_img) {
            //   this.homeSlide.push({ imgUrl: res.data.team_img });
            //   this.coverImg = res.data.team_img;
            // }
            this.homeSlide.push({ imgUrl: res.data.lroom_img });
            this.homeSlide.push({ imgUrl: res.data.restaurant_img });
            this.homeSlide.push({ imgUrl: res.data.mroom_img });
            this.homeSlide.push({ imgUrl: res.data.bedroom_img });
            this.topDetails = res.data;
            this.coverImg = res.data.lroom_img;
          }
        }).catch(err => console.error(err))
      },
      // 获取套餐产品详情
      getSetmealProduct(){
        this.$api.packDetails({
          setmeal_id: this.setmeal_id,
          type: 131,
        }).then(res => {
          res.data.forEach((item, index) => {
            item.forEach(it => {
              if((index == 0 || index == 1) && it.two_classification_name == "沙发"){
                this.packageList[0].items[0].items.push(it);
              }else if((index == 0 || index == 1) && it.two_classification_name == "茶几"){
                this.packageList[0].items[1].items.push(it);
              }else if((index == 2 || index == 3) && it.two_classification_name == "餐桌"){
                this.packageList[1].items[0].items.push(it);
              }else if((index == 2 || index == 3) && it.two_classification_name == "餐椅"){
                this.packageList[1].items[1].items.push(it);
              }else if((index == 4 || index == 5) && it.two_classification_name == "床"){
                this.packageList[2].items[0].items.push(it);
              }else if((index == 4 || index == 5) && it.two_classification_name == "床头柜"){
                this.packageList[2].items[1].items.push(it);
              }else if((index == 6 || index == 7) && it.two_classification_name == "床"){
                this.packageList[3].items[0].items.push(it);
              }else if((index == 6 || index == 7) && it.two_classification_name == "床头柜"){
                this.packageList[3].items[1].items.push(it);
              }
            })
            // 丰富区数据
            if(index == 8){
              item[0].forEach(ite => {
                if(ite.length){
                  ite.forEach(it => {
                    it.discountPrice = parseInt(Math.round(it.price * this.discount));
                    this.richProductsList.push(it)
                  })
                }
              })
            }
          })
          // 给每个对象添加一个checked
          this.packageList.forEach(item => {
            item.items.forEach(ite => {
              ite.items.forEach((it, idx) => {
                this.$set(it, "checked", idx ? false : true);
                if(it.checked){
                  this.selectItem.push(it);
                  this.totalPrice += it.price * it.goods_number;
                  this.totalDiscountPrice += parseInt(Math.round(it.price * it.goods_number * this.discount));
                  this.totalQuantity += it.goods_number;
                  ite.selectedQuantity += it.goods_number;
                }
              })
            })
          })
        }).catch(err => {throw new Error(err)})
      },
      changeChecked(obj, parentObj){
        if(obj.checked){
          this.selectItem.push(obj)
          this.totalPrice += obj.price * obj.goods_number;
          this.totalDiscountPrice += parseInt(Math.round(obj.price * obj.goods_number * this.discount));
          this.totalQuantity += obj.goods_number;
          parentObj.selectedQuantity += obj.goods_number;
        }else{
          this.totalPrice -= obj.price * obj.goods_number;
          this.totalDiscountPrice += parseInt(Math.round(obj.price * obj.goods_number * this.discount));
          this.totalQuantity -= obj.goods_number;
          parentObj.selectedQuantity -= obj.goods_number;

          this.selectItem.forEach((item, index) => {
            if(item.goods_id == obj.goods_id){
              this.selectItem.splice(index, 1)
            }
          })
        }
        // this.calcTotalQuantity();
      },
      richChangeChecked(obj){
        if(obj.checked){
          this.selectItem.push(obj)
          this.totalPrice += obj.price * obj.goods_number;
          this.totalDiscountPrice += parseInt(Math.round(obj.price * obj.goods_number * this.discount));
          this.totalQuantity += obj.goods_number;
        }else{
          this.totalPrice -= obj.price * obj.goods_number;
          this.totalDiscountPrice += parseInt(Math.round(obj.price * obj.goods_number * this.discount));
          this.totalQuantity -= obj.goods_number;
          this.selectItem.forEach((item, index) => {
            if(item.goods_id == obj.goods_id){
              this.selectItem.splice(index, 1)
            }
          })
        }
      },
      close(){
        this.show = !this.show;
      },
      toProductDetail(obj){
        dump.link({
          type: 1,
          link: "/product/details",
          params: {
            good_id: obj.goods_id
          }
        })
      },
      //提交订单-导出清单
      submitOrders(type){
        if (!dump.checkLogin()) {
          return false;
        }
        const schemeIs = [],
              nums = [],
              _self = this;
        this.selectItem.forEach(res => {
          schemeIs.push(res.scheme_id);
          nums.push(res.goods_number);
        })

        _self.$api.setmealSettlement({
          setmeal_id: _self.setmeal_id,
          type: 131,
          schemeIs: schemeIs.join(','),
          nums: nums.join(','),
          discount: this.discount
        }).then(res => {
          if(res.status == 103){
            _self.$message({
              message: res.msg,
              type: 'warning'
            });
            dump.link({
              type: 1,
              link: "/login"
            })
          }
          if(res.status == 100){
            if(type == 'dc'){
              window.open(url + "/appexpertorder/excelDetails.do?order_id=" + res.data.order_id)
            }else{
              _self.$message({
                message: '提交成功',
                type: 'success'
              });
    
              dump.link({
                type: 1,
                link: "/orderSettlement",
                params: {
                  orderid: res.data.order_id
                }
              })
            }
            
          }
        })
      },
      openThreed(url){
        dump.link({
          type: 2,
          link: url
        })
      },
      toScheme(schemeId){ 
        dump.link({
          type: 3,
          link: "/twodimensionalprogramme",
          params: {
            id: schemeId
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/style";
  @import "bagCheckDetail";
</style>